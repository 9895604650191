import { useState, useEffect } from "react"
import { gql, useQuery } from "@apollo/client"

export enum MembershipType {
    Basic = "BASIC",
    Gold = "GOLD",
}

export enum UserStatus {
    Active = "ACTIVE",
    Disqualified = "DISQUALIFIED",
}

export interface User {
    id: string
    username: string
    displayName: string
    membershipType: MembershipType
    status: UserStatus
    races: number
    points: number
    speed: number
    accuracy: number
}

export interface CompetitionContributors {
    id: string
    userID: string
    races: number
    points: number
}

export interface Competition {
    id: string
    startAt: string
    finishAt: string
    timeCycleAt: string
    users: User[]
    lastContributors: CompetitionContributors[]
}

interface CompetitionVariables {
    id: string
}

interface CompetitionResponse {
    competition: Competition
}

const query = gql`
    query Competition($id: ID!) {
        competition(id: $id) {
            id
            startAt
            finishAt
            timeCycleAt
            users {
                id
                username
                displayName
                membershipType
                status
                races
                points
                speed
                accuracy
            }
            lastContributors {
                id
                userID
                races
                points
            }
        }
    }
`

const subscriptionQuery = gql`
    subscription Competition($id: ID!) {
        competition(id: $id) {
            id
            startAt
            finishAt
            timeCycleAt
            users {
                id
                username
                displayName
                membershipType
                status
                races
                points
                speed
                accuracy
            }
            lastContributors {
                id
                userID
                races
                points
            }
        }
    }
`

/**
 * Hook used to grab competition data.
 */
export const useCompetitionQuery = (competitionID: string, subscribe?: boolean) => {
    const [isSubscribing, setIsSubscribing] = useState(false)
    const { data, loading, error, subscribeToMore } = useQuery<CompetitionResponse, CompetitionVariables>(query, {
        variables: {
            id: competitionID,
        },
    })

    useEffect(() => {
        if (!subscribe || isSubscribing) {
            return
        }
        subscribeToMore<CompetitionResponse, CompetitionVariables>({
            document: subscriptionQuery,
            variables: {
                id: competitionID,
            },
            updateQuery: (prev, options) => {
                if (!options.subscriptionData?.data) {
                    return prev
                }
                return options.subscriptionData.data
            },
        })
        setIsSubscribing(true)
    }, [subscribe, isSubscribing, competitionID, subscribeToMore])

    return {
        data: data?.competition,
        loading,
        error,
    }
}
