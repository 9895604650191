import React from "react"
import { useTheme, Box, Typography, Link, Grid, Paper } from "@mui/material"
import { PointsComp } from "../components/PointsComp"
import { CompNavigation } from "../components/CompNavigation"
import { CollectableCard, Rarity } from "../components/CollectableCard"

/**
 * Displays the landing page.
 */
export const Home = () => {
    const theme = useTheme()

    return (
        <main>
            <PointsComp competitionID={"17e6e5b0-e8e8-45be-a4dd-08c8526992d1"} />
            <CompNavigation eventOneCompetitionID={"17e6e5b0-e8e8-45be-a4dd-08c8526992d1"} />

            <Box component={"section"} sx={{ p: 2, my: 2 }}>
                <Typography variant={"h2"} gutterBottom sx={{ fontFamily: "Rajdhani, sans-serif", fontWeight: 600 }}>
                    Welcome All
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    To Celebrate <em>National Paper Airplane Day</em> on <em>May 26th, 2022</em>,{" "}
                    <Link href={"https://folly.team/"}>Folly Times!</Link> will be hosting a Big Comp!
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    View the above to track the Rules, Leaderboard and of course Prizes! GLHF!
                </Typography>
                <Typography
                    variant={"h3"}
                    gutterBottom
                    sx={{ fontFamily: "Rajdhani, sans-serif", fontWeight: 600, mt: 4 }}
                >
                    News - May 29th 2022
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    Event B is taking longer than expected due to my work schedule IRL. We are making good progress
                    though! For a sneak peek of what I plan, have a look below hehe. Until next time!
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    Don't forget to check out my initial plans for Event B over at{" "}
                    <Link href={"https://folly.team/posts/2022-05-11/#next-comp-coming-up-may-26th-part-iv"}>
                        https://folly.team/
                    </Link>
                    . It's an experimental turned base game involving race counts.
                </Typography>
                <Typography sx={{ mb: 2 }}>- Silje P. Entidy</Typography>
            </Box>

            <Box
                component={"section"}
                sx={{
                    py: 4,
                    mt: 2,
                    backgroundColor: "#2E3141",
                }}
            >
                <Typography
                    variant={"h2"}
                    gutterBottom
                    sx={{
                        fontFamily: "Rajdhani, sans-serif",
                        fontWeight: 600,
                        textAlign: "center",
                        color: "#eee",
                        mb: 1,
                    }}
                >
                    Coming Soon for Event B!
                </Typography>
                <Typography sx={{ color: "#ddd", textAlign: "center", fontWeight: 500, mb: 2 }}>
                    Paper Planes VS Dinosaurs - A collectable card game!
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        gap: 2,
                    }}
                >
                    <CollectableCard
                        name={"Chaos Flyer"}
                        description={
                            "An experiment gone wrong! The erratic flyer only has one shot at bringing chaos to your foes."
                        }
                        hp={30}
                        imgSrc={"/collectables/chaos-01.svg"}
                        effectDescription={"If inactive, this unit will destroy one Dinosaur per day."}
                        code={"PAPERPLANE-08"}
                        rarity={Rarity.Common}
                    />
                    <CollectableCard
                        name={"Rocket Paper Plane"}
                        description={"First ever experimental paper plane unit that aims to reach beyond the skies."}
                        hp={200}
                        attackName={"Rocket Charge"}
                        attackValue={50}
                        imgSrc={"/collectables/rocket-01.svg"}
                        code={"PAPERPLANE-19"}
                        rarity={Rarity.UltraRare}
                    />
                    <CollectableCard
                        name={"Folly Flyer"}
                        description={"Your very own Folly Times Fighter Plane hehe."}
                        hp={200}
                        attackName={"F Key"}
                        attackValue={60}
                        imgSrc={"/collectables/folly_flyer-01.svg"}
                        effectDescription={
                            "If this unit is destroyed, it will revive within 4 hours (collection is not lost)."
                        }
                        code={"PAPERPLANE-01"}
                        rarity={Rarity.UltraRare}
                    />
                </Box>
                <Typography
                    component={"p"}
                    variant={"caption"}
                    sx={{
                        fontFamily: "Rajdhani, sans-serif",
                        fontWeight: 600,
                        textAlign: "center",
                        my: 1,
                        color: "#eee",
                    }}
                >
                    NOTE: Card Game rulings subject to change hehe.
                </Typography>
            </Box>

            <Box component={"section"} sx={{ px: 2, py: 4, backgroundColor: "#2f5276", color: "#fff" }}>
                <Grid container spacing={2}>
                    <Grid item md={8} width={"100%"}>
                        <Typography
                            variant={"h3"}
                            gutterBottom
                            sx={{ fontFamily: "Rajdhani, sans-serif", fontWeight: 600 }}
                        >
                            Trivial
                        </Typography>
                        <Box
                            component={"ul"}
                            sx={{
                                "& li": {
                                    fontWeight: 300,
                                    fontSize: "0.875rem",
                                },
                            }}
                        >
                            <Typography component={"li"}>Copy Pasting from Xmaxx 2021 comp hehe</Typography>
                            <Typography component={"li"}>
                                Toonidy only uses the <em>Wrapped Flyer</em> for branding purposes
                            </Typography>
                            <Typography component={"li"}>
                                The Upcoming Event <em>Paper Plane vs Dinosaur</em> is based on a certain Discord user
                                pinging me over "obsession" with "paper planes"
                            </Typography>
                            <Typography component={"li"}>
                                The Big Competition Theme Idea was created when Nitro Type decided to celebrate May 4th
                                instead of giving out Paper Plane loot...
                            </Typography>
                            <Typography component={"li"}>
                                In a way to the above note: <em>this competition is a protest</em>
                            </Typography>
                            <Typography component={"li"}>
                                During my time in <em>Education Special Classes</em>, the first science lesson was to
                                make a <em>Darter Paper Airplane</em>
                            </Typography>
                            <Typography component={"li"}>
                                One of my Secret Santa Gifts was a book about making various Paper Airplanes hehe
                            </Typography>
                            <Typography component={"li"}>FreePik ftw?</Typography>
                            <Typography component={"li"}>Nitro Type! WRAPPED FLYER WHEN?</Typography>
                            <Typography component={"li"}>
                                Nitro Type! 1st June! National Dinosaur Day, sell Rex Loot!
                            </Typography>
                            <Typography component={"li"}>
                                Current World Record{" "}
                                <Link
                                    href="https://www.usatoday.com/story/news/world/2022/05/25/paper-airplane-guinness-world-record-south-korea/9930269002/"
                                    rel={"external noreferrer"}
                                    color={"#ddd"}
                                    target={"_blank"}
                                >
                                    252 feet and 7 inches
                                </Link>
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item md={4} width={"100%"}>
                        <Paper elevation={0} sx={{ p: 2, backgroundColor: "rgba(0, 0, 0, 0.2)", color: "#ddd" }}>
                            <Typography
                                component={"h3"}
                                sx={{
                                    fontFamily: "Rajdhani, sans-serif",
                                    fontSize: theme.typography.pxToRem(22),
                                    fontWeight: 600,
                                    textAlign: "center",
                                    textTransform: "uppercase",
                                    mb: 2,
                                }}
                            >
                                Want to Make Paper Planes?
                            </Typography>
                            <Typography sx={{ fontWeight: 300, fontSize: "0.875rem", textAlign: "center" }}>
                                Visit{" "}
                                <Link
                                    href={"https://www.foldnfly.com/"}
                                    target={"_blank"}
                                    rel={"external noreferrer"}
                                    color={"#ddd"}
                                >
                                    https://www.foldnfly.com/
                                </Link>
                                {" Do it hehe"}.
                            </Typography>
                            <Box
                                component={"img"}
                                src={"/paper_plane_sticker.webp"}
                                alt={"Nitro Type Paper Plane Sticker"}
                                sx={{
                                    display: "block",
                                    width: "96px",
                                    height: "71px",
                                    mt: 2,
                                    mx: "auto",
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </main>
    )
}
