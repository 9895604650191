import React from "react"
import { useTheme, Paper, Typography, Box } from "@mui/material"

/** Rarities list. */
export enum Rarity {
    Common = "Common",
    Rare = "Rare",
    SuperRare = "Super Rare",
    UltraRare = "Ultra Rare",
    Mythic = "Mythic",
}

/** Props for <CollectableCard>. */
interface Props {
    name: string
    description: React.ReactNode
    imgSrc: string
    hp: number
    attackName?: string
    attackValue?: number
    effectDescription?: string
    rarity: Rarity
    code: string
}

/**
 * Displays a Trading Collectable Card.
 */
export const CollectableCard = (props: Props) => {
    const { name, description, imgSrc, hp, rarity, code, attackName, attackValue, effectDescription } = props
    const theme = useTheme()

    return (
        <Paper
            elevation={0}
            sx={{
                display: "inline-block",
                width: "300px",
                borderRadius: "8px",
                backgroundColor: "#fee974",
                boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    m: "0.8rem",
                    p: 1,
                    height: "441px",
                    borderRadius: "8px",
                    backgroundColor: "#00b0eb",
                    backgroundImage: "url(/card_bg.jpg)",
                    backgroundSize: "cover",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography
                        sx={{
                            fontFamily: "Rajdhani,sans-serif",
                            fontSize: theme.typography.pxToRem(20),
                            fontWeight: 800,
                            color: "#333",
                            mb: 1,
                        }}
                    >
                        {name}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Rajdhani,sans-serif",
                            fontSize: theme.typography.pxToRem(18),
                            fontWeight: 600,
                            // textShadow: "2px 4px 3px rgba(0, 0, 0, 0.3)",
                            color: "#333",
                            mb: 1,
                        }}
                    >
                        <Typography
                            component={"span"}
                            sx={{
                                fontFamily: "Rajdhani,sans-serif",
                                fontSize: theme.typography.pxToRem(10),
                                fontWeight: 600,
                            }}
                        >
                            HP:
                        </Typography>
                        {` ${hp}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "8px",
                        // backgroundColor: "lightblue",
                        backgroundImage:
                            "radial-gradient(circle, rgba(235,235,235,0.8) 53%, rgba(161,207,255,0.8) 100%)",
                        // backgroundImage: "linear-gradient(to right, #0f0c29, #302b63, #24243e)",
                        // backgroundImage: getRarityBackgroundColor(Rarity.Mythic),
                        // backgroundColor: "rgba(255, 255, 255, 0.3)",
                        boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}
                >
                    <Box
                        component={"img"}
                        src={imgSrc}
                        alt={name}
                        sx={{
                            flexGrow: 1,
                            margin: "auto",
                            width: "250px",
                            height: "250px",
                        }}
                    />
                    {effectDescription && (
                        <Typography
                            sx={{
                                width: "100%",
                                p: 1,
                                boxSizing: "border-box",
                                borderBottomLeftRadius: "8px",
                                borderBottomRightRadius: "8px",
                                backgroundColor: "#fff",
                                fontSize: theme.typography.pxToRem(11),
                            }}
                        >
                            <strong>EFFECT:</strong> {effectDescription}
                        </Typography>
                    )}
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexGrow: 1,
                        alignItems: "center",
                        px: 2,
                        pt: !effectDescription ? "49px" : undefined,
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Rajdhani,sans-serif",
                            fontSize: theme.typography.pxToRem(18),
                            fontWeight: 600,
                            color: "#333",
                            mx: attackName ? undefined : "auto",
                        }}
                    >
                        {attackName ? attackName : <em>This unit has no attacks</em>}
                    </Typography>

                    {attackValue && (
                        <Typography
                            sx={{
                                fontFamily: "Rajdhani,sans-serif",
                                fontSize: theme.typography.pxToRem(18),
                                fontWeight: 600,
                                color: "#333",
                            }}
                        >
                            {attackValue}
                        </Typography>
                    )}
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "rgba(120, 120, 120, 0.3)",
                        borderRadius: "4px",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Rajdhani,sans-serif",
                            fontSize: theme.typography.pxToRem(10),
                            fontWeight: 600,
                            color: "#333",
                            py: "2px",
                            pl: 1,
                        }}
                    >
                        {code}
                    </Typography>
                    <Typography
                        sx={{
                            py: "2px",
                            px: 1,
                            borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                            fontFamily: "Rajdhani,sans-serif",
                            fontSize: theme.typography.pxToRem(10),
                            fontWeight: 600,
                            color: "#fff",
                            backgroundColor: "#888",
                            backgroundImage: getRarityBackgroundColor(rarity),
                            textTransform: "uppercase",
                        }}
                    >
                        {rarity}
                    </Typography>
                </Box>

                <Typography
                    sx={{
                        fontSize: theme.typography.pxToRem(10),
                        color: "#333",
                        pt: "4px",
                        fontWeight: 300,
                        minHeight: "3em",
                    }}
                >
                    {description}
                </Typography>
            </Box>
        </Paper>
    )
}

const getRarityBackgroundColor = (m: Rarity) => {
    switch (m) {
        case Rarity.Rare:
            return "linear-gradient(45deg, #c5c5c5, #3a3a3a)"
        case Rarity.SuperRare:
            return "linear-gradient(45deg, #2cc0ff, #143877)"
        case Rarity.UltraRare:
            return "linear-gradient(45deg, #c359ff, #4c2483)"
        case Rarity.Mythic:
            return "linear-gradient(45deg, #ea8d23, #78371d)"
    }
    return undefined
}
