import React from "react"
import ReactGA from "react-ga4"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { split, HttpLink, ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client"
import { getMainDefinition } from "@apollo/client/utilities"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"
import { ThemeProvider } from "@mui/material/styles"
import { App } from "./App"
import { MainTheme } from "./theme"
import reportWebVitals from "./reportWebVitals"

// Setup Google Analytics
if (process.env.NODE_ENV === "production" && process.env.REACT_APP_GA_MEASUREMENT_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID)
    ReactGA.send("pageview")
}

// Setup GraphQL Client
const httpLink = new HttpLink({
    uri: process.env.REACT_APP_HTTP_API_ENDPOINT || "http://localhost:8080/api/gql/query",
})

const wsLink = new GraphQLWsLink(
    createClient({
        keepAlive: 10000,
        url: process.env.REACT_APP_WS_API_ENDPOINT || "ws://localhost:8080/api/gql/query",
    })
)
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === "OperationDefinition" && definition.operation === "subscription"
    },
    wsLink,
    httpLink
)

const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
})

// Display root
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <BrowserRouter>
                <ThemeProvider theme={MainTheme}>
                    <App />
                </ThemeProvider>
            </BrowserRouter>
        </ApolloProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
