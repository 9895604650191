import React from "react"
import { Routes, Route } from "react-router-dom"
import { useIsMobile } from "./hooks/useIsMobile"
import { useTheme, Box, Container, Paper, Grid, Typography, Link } from "@mui/material"
import { Home } from "./routes/home"
import { Logo } from "./components/PageHeader/logo"

/**
 * Main App for Paper Plane Comp page.
 */
export const App = () => {
    const theme = useTheme()
    const { isMobile, mobileMediaQuery } = useIsMobile()

    return (
        <Box
            sx={{
                minHeight: "calc(100vh - 4rem)",
                [mobileMediaQuery]: {
                    minHeight: "100vh",
                },
            }}
        >
            <Container
                disableGutters={isMobile}
                sx={{
                    my: "2rem",
                    [mobileMediaQuery]: {
                        my: 0,
                    },
                }}
            >
                <Paper
                    elevation={0}
                    sx={{
                        borderRadius: "16px",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        [mobileMediaQuery]: {
                            borderRadius: "unset",
                        },
                    }}
                >
                    {/* Header */}
                    <Box
                        component={"header"}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            backgroundColor: "#839E53",
                            backgroundImage: "url(/paper_plane_bg.png)",
                            backgroundPosition: "bottom 16px right 16px",
                            backgroundRepeat: "no-repeat",
                            borderTopLeftRadius: "16px",
                            borderTopRightRadius: "16px",
                            p: 2,
                            [mobileMediaQuery]: {
                                borderTopLeftRadius: "unset",
                                borderTopRightRadius: "unset",
                            },
                            [theme.breakpoints.down(650)]: {
                                backgroundImage: "unset",
                            },
                        }}
                    >
                        <Logo />
                    </Box>

                    <Routes>
                        <Route path={"/"} element={<Home />} />
                    </Routes>

                    {/* Footer */}
                    <Box
                        component={"footer"}
                        sx={{
                            p: 2,
                            borderBottomLeftRadius: "14px",
                            borderBottomRightRadius: "14px",
                            backgroundColor: theme.palette.grey[800],
                            [mobileMediaQuery]: {
                                borderBottomLeftRadius: "unset",
                                borderBottomRightRadius: "unset",
                            },
                        }}
                    >
                        <Grid container component={"footer"} spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant={"body2"}
                                    sx={{
                                        position: "relative",
                                        color: theme.palette.getContrastText(theme.palette.background.default),
                                        [theme.breakpoints.down("sm")]: {
                                            textAlign: "center",
                                        },
                                    }}
                                >
                                    Copyright &copy; {new Date().getFullYear()}{" "}
                                    <Link href={"https://folly.team/"} sx={{ fontWeight: 600, color: "#eee" }}>
                                        Folly Times
                                    </Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Link
                                    href={"https://www.nitrotype.com/"}
                                    underline={"none"}
                                    target={"_blank"}
                                    rel={"external noreferrer"}
                                    color={"#eee"}
                                    title={"Visit Nitro Type to Learn to Type and have Fun!"}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        [theme.breakpoints.down("sm")]: {
                                            justifyContent: "center",
                                            textAlign: "center",
                                        },
                                    }}
                                >
                                    <img src={"/nt-16x16.png"} alt={"Nitro Type Logo"} />
                                    <Typography
                                        component={"span"}
                                        variant={"body2"}
                                        sx={{
                                            ml: "1ch",
                                            fontSize: theme.typography.pxToRem(12),
                                            color: "rgba(255, 255, 255, 0.7)",
                                        }}
                                    >
                                        Visit Nitro Type
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Container>
        </Box>
    )
}
