import React, { useState } from "react"
import dayjs from "dayjs"
import { useCompetitionQuery } from "../../hooks/useCompetition"
import { useTheme, Box, Grid, Typography, Skeleton, Button } from "@mui/material"
import { EmojiEvents } from "@mui/icons-material"
import { LeaderboardTable } from "./leaderboardTable"
import { LeaderboardDialog } from "./leaderboardDialog"
import { CountdownChip } from "./countdown"

/** Props for <PointsComp />. */
interface Props {
    competitionID: string
}

/**
 * Displays the Most Points comp section.
 */
export const PointsComp = (props: Props) => {
    const theme = useTheme()

    const { data, loading } = useCompetitionQuery(props.competitionID, true)

    const [showLeaderboardDialog, setShowLeaderboardDialog] = useState(false)

    const startAt = data ? new Date(data.startAt) : undefined
    const finishAt = data ? new Date(data.finishAt) : undefined

    return (
        <Box
            component={"section"}
            sx={{
                backgroundColor: "#2E3141",
                color: "#eee",
                p: 2,
            }}
        >
            <Grid container spacing={2}>
                <Grid item width={"100%"} md={5}>
                    <Box
                        sx={{ p: 2, borderRadius: theme.typography.pxToRem(8), backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                    >
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
                            <Typography
                                component={"h1"}
                                sx={{
                                    fontFamily: "Rajdhani, sans-serif",
                                    fontWeight: 600,
                                    fontSize: theme.typography.pxToRem(20),
                                    textTransform: "uppercase",
                                    textShadow: "2px 4px 3px rgba(0, 0, 0, 0.3)",
                                }}
                            >
                                Event A - Fly Your Gliders!
                            </Typography>

                            {!loading && startAt && finishAt && <CountdownChip from={startAt} to={finishAt} />}
                            {loading && (
                                <Skeleton
                                    variant={"rectangular"}
                                    width={"200px"}
                                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}
                                />
                            )}
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            {loading && (
                                <Skeleton
                                    variant={"rectangular"}
                                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}
                                />
                            )}
                            {!loading && startAt && finishAt && (
                                <Typography sx={{ fontWeight: 300, fontSize: theme.typography.pxToRem(14) }}>
                                    <em>{dayjs(startAt).format("DD MMM YYYY hh:mm A")}</em> to{" "}
                                    <em>{dayjs(finishAt).format("DD MMM YYYY hh:mm A")}</em>
                                </Typography>
                            )}
                        </Box>
                        <LeaderboardTable competitionID={props.competitionID} limit={10} />
                        <Button
                            type={"button"}
                            variant={"contained"}
                            color={"primary"}
                            startIcon={<EmojiEvents />}
                            fullWidth
                            onClick={() => setShowLeaderboardDialog(true)}
                            sx={{ mt: 1 }}
                        >
                            Leaderboard
                        </Button>
                        {data && (
                            <Typography component={"div"} variant={"caption"} sx={{ mt: 1, fontWeight: 300 }}>
                                Last updated {dayjs(data.timeCycleAt).format("DD MMM YYYY hh:mm A")}
                            </Typography>
                        )}
                        {loading && (
                            <Skeleton
                                variant={"rectangular"}
                                sx={{ mt: 1, backgroundColor: "rgba(255, 255, 255, 0.3)" }}
                            />
                        )}
                    </Box>
                </Grid>
                <Grid item width={"100%"} md={7}>
                    <section>
                        <Typography
                            component={"h2"}
                            sx={{
                                fontFamily: "Rajdhani, sans-serif",
                                fontWeight: 600,
                                fontSize: theme.typography.pxToRem(20),
                                textTransform: "uppercase",
                                mb: 2,
                            }}
                        >
                            Rules
                        </Typography>
                        <Typography gutterBottom sx={{ fontWeight: 300 }}>
                            Most points wins! Fly your gliders and see how far it gets by racing on Nitro Type hehe.
                        </Typography>
                        <Typography component={"div"} variant={"caption"} gutterBottom sx={{ fontWeight: 300 }}>
                            1. 25 races required to enter the Leaderboard.
                        </Typography>
                        <Typography component={"div"} variant={"caption"} sx={{ fontWeight: 300 }}>
                            2. Points Formula <code>(100 + (wpm / 2)) * (accuracy / 100)</code> (aka Season Points)
                        </Typography>
                    </section>

                    <Box component={"section"} sx={{ mt: 2 }}>
                        <Typography
                            component={"h2"}
                            sx={{
                                fontFamily: "Rajdhani, sans-serif",
                                fontWeight: 600,
                                fontSize: theme.typography.pxToRem(20),
                                textTransform: "uppercase",
                                mb: 2,
                            }}
                        >
                            Prizes
                        </Typography>
                        <Typography sx={{ fontWeight: 300 }}>
                            1x Nitro Type to the Top <em>Non-Gold</em> Player. For <em>Already-Gold</em> Players, there
                            will be 125 million! But that's not all!
                        </Typography>
                        <Box
                            component={"ul"}
                            sx={{
                                "& li": {
                                    fontWeight: 300,
                                    fontSize: "0.875rem",
                                },
                            }}
                        >
                            <Typography component={"li"}>1st = 50 mil</Typography>
                            <Typography component={"li"}>2nd = 25 mil</Typography>
                            <Typography component={"li"}>3rd = 12 mil</Typography>
                            <Typography component={"li"}>4th = 5 mil</Typography>
                            <Typography component={"li"}>5th = 2.5 mil</Typography>
                            <Typography component={"li"}>6th = 1 mil</Typography>
                            <Typography component={"li"}>7th = 800k</Typography>
                            <Typography component={"li"}>8th = 600k</Typography>
                            <Typography component={"li"}>9th = 500k</Typography>
                            <Typography component={"li"}>10th = 300k</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <LeaderboardDialog
                data={data}
                show={showLeaderboardDialog}
                onClose={() => setShowLeaderboardDialog(false)}
            />
        </Box>
    )
}
