import React from "react"
import dayjs from "dayjs"
import { Competition } from "../../hooks/useCompetition"
import { useIsMobile } from "../../hooks/useIsMobile"
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from "@mui/material"
import { LeaderboardTable } from "./leaderboardTable"

/** Props for <LeaderboardDialog>. */
interface Props {
    data?: Competition
    show: boolean
    onClose: () => void
}

/**
 * Displays a dialog showing the full leaderboard.
 */
export const LeaderboardDialog = (props: Props) => {
    const { data, show, onClose } = props
    const { isMobile } = useIsMobile()

    return (
        <Dialog open={show && !!data} maxWidth={"md"} fullWidth fullScreen={isMobile} onClose={onClose}>
            <DialogTitle>
                Event A - Fly your Gliders!
                {data && (
                    <Typography variant={"body2"}>
                        {dayjs(data.startAt).format("DD MMM YYYY hh:mm A")} to{" "}
                        {dayjs(data.finishAt).format("DD MMM YYYY hh:mm A")}
                    </Typography>
                )}
            </DialogTitle>
            <DialogContent>
                {data && (
                    <>
                        <LeaderboardTable
                            competitionID={data.id}
                            showFull
                            stickyHeader
                            sx={{ maxHeight: isMobile ? "80vh" : "60vh", overflowY: "scroll" }}
                        />
                        <Typography component={"div"} variant={"caption"} sx={{ mt: 1, fontWeight: 300 }}>
                            Last updated {dayjs(data.timeCycleAt).format("DD MMM YYYY hh:mm A")}
                        </Typography>
                    </>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <Typography component={"p"} variant={"caption"}>
                    Scroll through the table to view all the participants.
                </Typography>
                <Button type={"button"} variant={"contained"} color={"primary"} onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
