import React from "react"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { Chip, Typography } from "@mui/material"

dayjs.extend(relativeTime)

/** Props for <CompCountdownChip>. */
interface Props {
    from: Date
    to: Date
}

/**
 * Displays Comp Countdown chip.
 */
export const CountdownChip = (props: Props) => {
    const status = generateCompCountdownStatus(props)

    return (
        <Chip
            variant={"filled"}
            color={"error"}
            label={status}
            sx={{
                fontFamily: "Rajdhani,sans-serif",
                fontWeight: 800,
            }}
        />
    )
}

/**
 * Displays Comp Countdown text.
 */
export const CountdownText = (props: Props) => {
    const status = generateCompCountdownStatus(props)

    return (
        <Typography component={"p"} variant={"caption"} color={"grey.300"} sx={{ mt: 1 }}>
            {status} ({dayjs(props.to).format("DD MMM YYYY hh:mm A")})
        </Typography>
    )
}

/**
 * Generates Comp Countdown status text.
 */
const generateCompCountdownStatus = (comp: Props) => {
    const now = new Date()
    if (now < comp.from) {
        return `Starts ${dayjs(comp.from).fromNow()}`
    } else if (now < comp.to) {
        return `Ends ${dayjs(comp.to).fromNow()}`
    }
    return "Ended"
}
