import React from "react"
import dayjs from "dayjs"
import { useCompetitionQuery } from "../../hooks/useCompetition"
import { Box, Grid, Paper, Typography, Chip } from "@mui/material"

/** Props for <PointsComp />. */
interface Props {
    eventOneCompetitionID: string
}

/**
 * Displays a navigation menu to select between comp events.
 */
export const CompNavigation = (props: Props) => {
    const { data: eventOneData } = useCompetitionQuery(props.eventOneCompetitionID, true)

    return (
        <Box
            component={"section"}
            sx={{
                p: 1,
                backgroundColor: "#111",
                color: "#eee",
            }}
        >
            <Grid container spacing={1}>
                <Grid item width={"100%"} md={6}>
                    <NavigationItem
                        label={"Event A - Fly Your Gliders"}
                        dateRange={
                            eventOneData
                                ? {
                                      from: new Date(eventOneData.startAt),
                                      to: new Date(eventOneData.finishAt),
                                  }
                                : undefined
                        }
                        backgroundImageFilename={"/event_a_bg.jpg"}
                    />
                </Grid>
                <Grid item width={"100%"} md={6}>
                    <NavigationItem
                        label={"Event B - Paper Planes vs Dinosaurs"}
                        comingSoon
                        backgroundImageFilename={"/event_b_bg.png"}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

/** Props for <NavigationItem>. */
interface NavigationItemProps {
    label: string
    dateRange?: {
        from: Date
        to: Date
    }
    comingSoon?: boolean
    backgroundImageFilename: string
}

/**
 * Displays a Navigation Menu Item.
 */
const NavigationItem = (props: NavigationItemProps) => {
    const { label, dateRange, comingSoon, backgroundImageFilename } = props

    return (
        <Paper
            elevation={0}
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "240px",
                backgroundImage: `url(${backgroundImageFilename})`,
                backgroundPosition: "center bottom",
                backgroundSize: "cover",
            }}
        >
            <Typography
                sx={{
                    mt: "auto",
                    p: 1,
                    backgroundColor: "#333",
                    color: "#eee",
                    textAlign: "center",
                }}
            >
                <Box
                    component={"span"}
                    sx={{
                        color: "yellow",
                        fontFamily: "Rajdhani, sans-serif",
                        fontWeight: 600,
                        textTransform: "uppercase",
                        textShadow: "2px 4px 3px rgba(0, 0, 0, 0.3)",
                    }}
                >
                    {label}
                </Box>
                {dateRange && (
                    <Chip
                        variant={"filled"}
                        color={"error"}
                        label={`${dayjs(dateRange.from).format("DD MMM YYYY")} to ${dayjs(dateRange.to).format(
                            "DD MMM YYYY"
                        )}`}
                        size={"small"}
                        sx={{
                            ml: 1,
                            fontFamily: "Rajdhani,sans-serif",
                            fontWeight: 800,
                        }}
                    />
                )}
                {comingSoon && (
                    <Chip
                        variant={"filled"}
                        color={"error"}
                        label={"Coming Soon"}
                        size={"small"}
                        sx={{
                            ml: 1,
                            fontFamily: "Rajdhani,sans-serif",
                            fontWeight: 800,
                        }}
                    />
                )}
            </Typography>
        </Paper>
    )
}
