import React from "react"
import {
    styled,
    useTheme,
    Theme,
    Skeleton,
    Box,
    Alert,
    Typography,
    Link,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    SxProps,
} from "@mui/material"
import { useCompetitionQuery, MembershipType, UserStatus, User } from "../../hooks/useCompetition"
import { RANKS } from "./constants"
import NTGoldIcon from "../../assets/images/nt-gold-icon.png"

const DEFAULT_MIN_RACES = 25

/** Table Header Cell. */
const TableHeaderCell = styled(TableCell)(() => ({
    backgroundColor: "#697f42",
    color: "#eee",
}))

/** Leaderboard User data. */
interface LeaderboardUser extends User {
    contributedRaces?: number
    contributedPoints?: number
}

/** Props for <LeaderboardTable>. */
interface Props {
    competitionID: string
    minRaces?: number
    limit?: number
    elevation?: number
    showFull?: boolean
    stickyHeader?: boolean
    sx?: SxProps<Theme>
}

/**
 * Displays the Leaderboard Table.
 */
export const LeaderboardTable = (props: Props) => {
    const { showFull } = props
    const minRaces = props.minRaces || DEFAULT_MIN_RACES
    const theme = useTheme()
    const { data, loading, error } = useCompetitionQuery(props.competitionID)

    if (error) {
        return <Alert severity={"error"}>Something went wrong with fetching latest leaderboard wah.</Alert>
    }

    let leaderboardUsers: (LeaderboardUser | undefined)[] | undefined = data?.users
        .filter((u) => u.username !== "follycakes" && u.status === UserStatus.Active && u.races >= minRaces)
        .map((u) => {
            const c = data.lastContributors.find((c) => c.userID === u.id)
            return {
                ...u,
                contributedRaces: c?.races,
                contributedPoints: c?.points,
            }
        })
    if (props.limit && leaderboardUsers) {
        leaderboardUsers = leaderboardUsers?.slice(0, 10)
        if (leaderboardUsers.length < props.limit) {
            leaderboardUsers = leaderboardUsers.concat(Array(props.limit - leaderboardUsers.length).fill(null))
        }
    }

    const startAt = data ? new Date(data.startAt) : undefined
    const currentTime = new Date()
    const compStarted = startAt && startAt <= currentTime
    const emptyRowColSpan = showFull ? 6 : 3

    return (
        <TableContainer component={Paper} elevation={props.elevation} sx={{ ...props.sx }}>
            <Table size={"small"} stickyHeader={!!props.stickyHeader}>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Rank</TableHeaderCell>
                        <TableHeaderCell>Member</TableHeaderCell>
                        {showFull && (
                            <>
                                <TableHeaderCell align={"center"}>Speed</TableHeaderCell>
                                <TableHeaderCell align={"center"}>Accuracy</TableHeaderCell>
                                <TableHeaderCell align={"right"}>Races</TableHeaderCell>
                            </>
                        )}
                        <TableHeaderCell align={"right"}>Points</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!loading && !compStarted && (
                        <TableRow>
                            <TableCell colSpan={emptyRowColSpan} align={"center"}>
                                Competition hasn't started!
                            </TableCell>
                        </TableRow>
                    )}
                    {!loading &&
                        compStarted &&
                        leaderboardUsers &&
                        leaderboardUsers.filter((u) => u !== null).length === 0 && (
                            <TableRow>
                                <TableCell colSpan={emptyRowColSpan} align={"center"}>
                                    No one has raced {minRaces} races yet wah.
                                </TableCell>
                            </TableRow>
                        )}
                    {!loading &&
                        compStarted &&
                        leaderboardUsers &&
                        leaderboardUsers.filter((u) => u !== null).length > 0 &&
                        leaderboardUsers.map((r, i) => {
                            if (!r) {
                                return (
                                    <TableRow key={`blitz-lb-grind-${i}`}>
                                        <TableCell>{RANKS[i] || ordinalSuffixOf(i + 1)}</TableCell>
                                        <TableCell colSpan={emptyRowColSpan - 1}>
                                            <em>N/A</em>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            return (
                                <TableRow key={`blitz-lb-grind-${i}`}>
                                    <TableCell>{RANKS[i] || ordinalSuffixOf(i + 1)}</TableCell>
                                    <TableCell>
                                        <Link
                                            href={`https://www.nitrotype.com/racer/${r.username}`}
                                            target={"_blank"}
                                            rel={"external noreferrer"}
                                            color={"#222"}
                                        >
                                            {r.membershipType === MembershipType.Gold && (
                                                <>
                                                    <Box
                                                        component={"img"}
                                                        src={NTGoldIcon}
                                                        alt={"Nitro Type Gold Icon"}
                                                        sx={{ width: "24px", height: "18px" }}
                                                    />
                                                    &nbsp;
                                                </>
                                            )}
                                            <strong>{r.displayName}</strong>
                                        </Link>
                                    </TableCell>
                                    {showFull && (
                                        <>
                                            <TableCell align={"center"}>{r.speed.toFixed(2)} WPM</TableCell>
                                            <TableCell align={"center"}>{r.accuracy.toFixed(2)}%</TableCell>
                                            <TableCell align={"right"}>
                                                {r.races}
                                                {r.contributedRaces && (
                                                    <Typography
                                                        variant={"caption"}
                                                        sx={{ color: theme.palette.success.main }}
                                                    >
                                                        {` (+${r.contributedRaces})`}
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        </>
                                    )}
                                    <TableCell align={"right"}>
                                        {r.points}
                                        {r.contributedPoints && (
                                            <Typography variant={"caption"} sx={{ color: theme.palette.success.main }}>
                                                {` (+${r.contributedPoints})`}
                                            </Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    {(loading || !leaderboardUsers) &&
                        Array(props.limit || 5)
                            .fill(0)
                            .map((_, i) => (
                                <TableRow key={`blitz-lb-grind-loading-${i}`}>
                                    <TableCell colSpan={emptyRowColSpan}>
                                        <Skeleton variant={"rectangular"} />
                                    </TableCell>
                                </TableRow>
                            ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

/** Adds in the ordinal suffix from a specific number. */
const ordinalSuffixOf = (i: number) => {
    var j = i % 10,
        k = i % 100
    if (j === 1 && k !== 11) {
        return i + "st"
    }
    if (j === 2 && k !== 12) {
        return i + "nd"
    }
    if (j === 3 && k !== 13) {
        return i + "rd"
    }
    return i + "th"
}
