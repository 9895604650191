/** Ranks List for iterating purposes. */
export const RANKS = [
    "🥇 1st",
    "🥈 2nd",
    "🥉 3rd",
    "🎖️ 4th",
    "🎖️ 5th",
    "🎖️ 6th",
    "🎖️ 7th",
    "🎖️ 8th",
    "🎖️ 9th",
    "🎖️ 10th",
]
